// Angular
import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';

import * as moment from 'moment';


moment.locale('pl');

@Component({
  selector: 'kt-calendar',
  templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit, AfterViewInit {

    @Output()
    calendarPeriod = new EventEmitter<string>();
    @Output()
    calendarRange = new EventEmitter<any>();
  locale = {
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
    customRangeLabel: 'Zakres niestandardowy',
    applyLabel: 'Zastosuj'
  };

  selected: any;
  alwaysShowCalendars: boolean;
  showRangeLabelOnInput: boolean;
  keepCalendarOpeningWithRange: boolean;
  maxDate: moment.Moment;
  minDate: moment.Moment;
  // invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  ranges: any = {
    Dzisiaj: [moment(), moment()],
    Wczoraj: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Ostatnie 7 dni': [moment().subtract(6, 'days'), moment()],
    'Ostatnie 30 dni': [moment().subtract(29, 'days'), moment()],
    'Ten miesiąc': [moment().startOf('month'), moment().endOf('month')],
    'Ostatni miesiąc': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  };
  // Zakres dat przesyłany do wykresów
  range: any;
  // Zakres dat przesyłany do komponentu wykresu
  dates: any;
  // Okres H/D/W/M przesyłany do komponentu wykresu
  period: any;

  // Sprawdza czy dane zostały wybrane
  emitted: any;

  constructor(
  ) {
    this.keepCalendarOpeningWithRange = true;
    this.showRangeLabelOnInput = true;
    this.selected = { startDate: moment().subtract(6, 'days'), endDate: moment() };
  }
  rangeClicked(range) {
    this.emitted = true;
    this.dates = range;
    this.calendarRange.emit(range);

  }
  datesUpdated(range) {
    range = this.selected;
    if(this.emitted == true) {
      this.calendarRange.emit(range);
    }
  }
  updatePeriod(period) {
    this.calendarPeriod.emit(period);
    this.period = period;
  }
  ngOnInit() {
      this.emitted = false;
      this.period = 'day';
  }
  ngAfterViewInit() {
    this.emitted = true;
  }
}
