// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { CalendarComponent } from './calendar.component';


@NgModule({
  imports: [
    CommonModule,
    PartialsModule,
    CoreModule,
    FormsModule,
    MatProgressSpinnerModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [],
  declarations: [CalendarComponent],
  exports: [
      CalendarComponent,
      MatProgressSpinnerModule
  ]

})
export class CalendarModule {}
