import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class DiagnosticsService {
  url = environment.url;

  constructor(private http: HttpClient) {}

  // Zwraca listę POS nieaktywnych ponad 24h
  getDiagnosticsInactivePosList() {
    return this.http.get<{ data: any[] }>(
      `${this.url}/diagnostics/sensor-list`
    );
  }
  // Dane na potrzeby wygenerowania pliku Excel z danymi Raportujące/Nieraportujące oraz Zaczęły raportować/Przestały raportować
  getDiagnosticsExport(begin, end) {
    return this.http.get<{ data: any[] }>(
      `${this.url}/diagnostics/export?begin=${begin}&end=${end}`
    );
  }
  // Dane na potrzeby wykresu Raportujące/Nieraportujące oraz Zaczęły raportować/Przestały raportować

  getDiagnosticsActivityChart(begin, end) {
    return this.http.get<{ data }>(
      `${this.url}/diagnostics/activity-chart?begin=${begin}&end=${end}`
    );
  }
  getDiagnosticsReportingChart(begin, end) {
    return this.http.get<{ data }>(
      `${this.url}/diagnostics/reporting-chart?begin=${begin}&end=${end}`
    );
  }

  // Zwraca listę prywatnych notatek dla danego POS
  getDiagnosticsPrivateComments(id) {
    return this.http.get<{ data }>(
      `${this.url}/diagnostics/private-comments/${id}`
    );
  }
  // Zwraca listę publicznych notatek dla danego POS
  getDiagnosticsPublicComments(id) {
    return this.http.get<{ data }>(
      `${this.url}/diagnostics/public-comments/${id}`
    );
  }

  addDiagnosticsPublicComment(id: number, comment: string) {
    return this.http.post<any>(`${this.url}/diagnostics/public-comment`, {
      id,
      comment
    });
  }

  addDiagnosticsPrivateComment(id: number, comment: string) {
    console.log(comment);
    return this.http.post<any>(`${this.url}/diagnostics/private-comment`, {
      id,
      comment
    });
  }
}
