import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class KrRksService {
  url = environment.url;

  constructor(private http: HttpClient) {}

  // Zwraca listę KR
  getKrList() {
    return this.http.get<{ data: any[] }>(`${this.url}/kr/list`);
  }

  // Aktualizuje dane KR
  updateKr(idkr, imie_nazwisko, email, tel) {
    return this.http.post<any>(`${this.url}/kr/update-kr`, {idkr, imie_nazwisko, email, tel});
  }

  // Zwraca listę RKS
  getRksList() {
    return this.http.get<{ data: any[] }>(`${this.url}/rks/list`);
  }

  // Aktualizuje dane KR
  updateRks(idrks, imie_nazwisko, email, tel) {
    return this.http.post<any>(`${this.url}/rks/update-rks`, {idrks, imie_nazwisko, email, tel});
  }
}
